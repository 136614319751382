import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Testing the Alarmserver",
  "path": "/Frequently_Asked_Question/Alarmserver_Testing/",
  "dateChanged": "2022-10-26",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my camera to notify my smarthome and home alarm system if it spots an intruder.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Testing the Alarmserver' dateChanged='2022-10-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my camera to notify my smarthome and home alarm system if it spots an intruder.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Alarmserver_Testing/' locationFR='/fr/Frequently_Asked_Question/Alarmserver_Testing/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "testing-the-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#testing-the-alarmserver",
        "aria-label": "testing the alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing the Alarmserver`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use my camera to notify my smarthome and home alarm system if it spots an intruder.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: You can use your camera's Alarm Server to send a short `}<em parentName="p">{`Ping`}</em>{` whenever an alarm event was triggered. Your INSTAR WQHD camera supports both the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`HTTP`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTT`}</a>{` protocol to notify your third-party systems. You can use the following CGI commands to test this function and to help you configure the receiving end, like e.g. `}<strong parentName="p">{`Node-RED`}</strong>{`.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#testing-the-alarmserver"
        }}>{`Testing the Alarmserver`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdtestas"
            }}>{`param.cgi?cmd=testas`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#test-the-http-alarmserver"
                }}>{`Test the HTTP Alarmserver`}</a>
                <ul parentName="li">
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#alarm-trigger"
                    }}>{`Alarm Trigger`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#detected-object"
                    }}>{`Detected Object`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#test-result"
                    }}>{`Test Result`}</a></li>
                </ul>
              </li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#smarthome-integration"
                }}>{`Smarthome Integration`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdtestmqtt"
            }}>{`param.cgi?cmd=testmqtt`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#test-the-mqtt-alarmserver"
                }}>{`Test the MQTT Alarmserver`}</a>
                <ul parentName="li">
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#alarm-trigger-1"
                    }}>{`Alarm Trigger`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#detected-object-1"
                    }}>{`Detected Object`}</a></li>
                </ul>
              </li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#smarthome-integration-1"
                }}>{`Smarthome Integration`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "paramcgicmdtestas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdtestas",
        "aria-label": "paramcgicmdtestas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=testas`}</h2>
    <h3 {...{
      "id": "test-the-http-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#test-the-http-alarmserver",
        "aria-label": "test the http alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test the HTTP Alarmserver`}</h3>
    <p>{`This command allows you to emit a alarmserver HTTP request `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`as configured`}</a>{` in the web user interface of your camera. You can add the trigger and detected object as `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"
      }}>{`URL queries`}</a>{`:`}</p>
    <h4 {...{
      "id": "alarm-trigger",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#alarm-trigger",
        "aria-label": "alarm trigger permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Trigger`}</h4>
    <ul>
      <li parentName="ul">{`Alarm Area 1 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=1`}</code></li>
      <li parentName="ul">{`Alarm Area 2 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=2`}</code></li>
      <li parentName="ul">{`Alarm Area 3 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=3`}</code></li>
      <li parentName="ul">{`Alarm Area 4 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=4`}</code></li>
      <li parentName="ul">{`Alarm-In / PIR triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=5`}</code></li>
      <li parentName="ul">{`Audio Alarm triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=6`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 1 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=7`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 2 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=8`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 3 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=9`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 4 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=10`}</code></li>
      <li parentName="ul">{`Manual trigger (`}<em parentName="li">{`cmd=pushhostalarm`}</em>{`): `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=99`}</code></li>
    </ul>
    <h4 {...{
      "id": "detected-object",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#detected-object",
        "aria-label": "detected object permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Detected Object`}</h4>
    <ul>
      <li parentName="ul">{`Person: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=1`}</code></li>
      <li parentName="ul">{`Vehicle: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=2`}</code></li>
      <li parentName="ul">{`Person + Vehicle: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=3`}</code></li>
      <li parentName="ul">{`Animal: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=4`}</code></li>
      <li parentName="ul">{`Person + Animal: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=5`}</code></li>
      <li parentName="ul">{`Animal + Vehicle: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=6`}</code></li>
    </ul>
    <p>{`So, for example, you want to simulate that your cameras first alarm area and (`}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`coupled`}</a>{`) the internal PIR motion sensor detected motion. And the activated `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`object detection`}</a>{` identified the object as being a person. If your cameras IP address is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.120/`}</code>{` and your admin user is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin`}</code>{` with password `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar`}</code>{` you can visit the following URL with your web browser to trigger the Alarmserver:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.120/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`testas`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`index`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`active`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`object`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e23820586aca463472d6b7269a705fef/23266/Alarmserver_Testing_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAXklEQVQI143KSwqAMAxF0a7DpgNTmzSNDVVB3P/KBAV/I+EMLo/ntMxma+YmeRI2ZdMyqTSmmgalVJkqpfFwB7NRGt0yb9hn8Bggnq4Gjy+AATA8FlekRRTf9d/rDztj2xhPmipDqgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e23820586aca463472d6b7269a705fef/e4706/Alarmserver_Testing_01.avif 230w", "/en/static/e23820586aca463472d6b7269a705fef/d1af7/Alarmserver_Testing_01.avif 460w", "/en/static/e23820586aca463472d6b7269a705fef/7f308/Alarmserver_Testing_01.avif 920w", "/en/static/e23820586aca463472d6b7269a705fef/fbc96/Alarmserver_Testing_01.avif 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e23820586aca463472d6b7269a705fef/a0b58/Alarmserver_Testing_01.webp 230w", "/en/static/e23820586aca463472d6b7269a705fef/bc10c/Alarmserver_Testing_01.webp 460w", "/en/static/e23820586aca463472d6b7269a705fef/966d8/Alarmserver_Testing_01.webp 920w", "/en/static/e23820586aca463472d6b7269a705fef/e5eeb/Alarmserver_Testing_01.webp 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e23820586aca463472d6b7269a705fef/81c8e/Alarmserver_Testing_01.png 230w", "/en/static/e23820586aca463472d6b7269a705fef/08a84/Alarmserver_Testing_01.png 460w", "/en/static/e23820586aca463472d6b7269a705fef/c0255/Alarmserver_Testing_01.png 920w", "/en/static/e23820586aca463472d6b7269a705fef/23266/Alarmserver_Testing_01.png 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e23820586aca463472d6b7269a705fef/c0255/Alarmserver_Testing_01.png",
              "alt": "Testing the INSTAR Alarmserver",
              "title": "Testing the INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "test-result",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#test-result",
        "aria-label": "test result permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test Result`}</h4>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`result`}</code>{`:`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`0`}</code>{`: Success`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-1`}</code>{`: Can’t connect to Server`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-2`}</code>{`: Invalid credentials`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-3`}</code>{`: Permission error / Bad path`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-4`}</code>{`: Timeout`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-5`}</code>{`: Server cannot be verified`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-6`}</code>{`: Busy`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-7`}</code>{`: Internal error`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-8`}</code>{`: Unknown error`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-9`}</code>{`: Transfer incomplete`}</li>
        </ul>
      </li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Beside the `}<a parentName="p" {...{
          "href": "/en/1440p_Series_CGI_List/#response-codes"
        }}>{`default response code`}</a>{` this command can also return the code `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`210`}</code>{` which indicates that the alarmserver is disabled or `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`215`}</code>{` could not be triggered because something is blocking it. Is the Alarmserver suppressed for the trigger you are trying to use? See `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`as_area`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`as_io`}</code>{`, etc. (`}<a parentName="p" {...{
          "href": "/en/1440p_Series_CGI_List/Smarthome_Menu/Alarm_Server/#paramcgicmdgetasattr"
        }}>{`see CGI Documentation`}</a>{`)`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/be866a60960a0d9d94b0b05d31172f73/681f1/Alarmserver_Testing_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "10.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAASklEQVQI15XHWwqAMAxE0a7DB5iZpG1iW/Gj+9+a6A6Ey4GbRp/XmOG3acvWDZG1ldxNT8IBVwYZhBOvkKrfylESUSF137gu8rcHJwIQDFElWSQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be866a60960a0d9d94b0b05d31172f73/e4706/Alarmserver_Testing_02.avif 230w", "/en/static/be866a60960a0d9d94b0b05d31172f73/d1af7/Alarmserver_Testing_02.avif 460w", "/en/static/be866a60960a0d9d94b0b05d31172f73/06569/Alarmserver_Testing_02.avif 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/be866a60960a0d9d94b0b05d31172f73/a0b58/Alarmserver_Testing_02.webp 230w", "/en/static/be866a60960a0d9d94b0b05d31172f73/bc10c/Alarmserver_Testing_02.webp 460w", "/en/static/be866a60960a0d9d94b0b05d31172f73/8bcb7/Alarmserver_Testing_02.webp 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be866a60960a0d9d94b0b05d31172f73/81c8e/Alarmserver_Testing_02.png 230w", "/en/static/be866a60960a0d9d94b0b05d31172f73/08a84/Alarmserver_Testing_02.png 460w", "/en/static/be866a60960a0d9d94b0b05d31172f73/681f1/Alarmserver_Testing_02.png 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/be866a60960a0d9d94b0b05d31172f73/681f1/Alarmserver_Testing_02.png",
              "alt": "Testing the INSTAR Alarmserver",
              "title": "Testing the INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "smarthome-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#smarthome-integration",
        "aria-label": "smarthome integration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Integration`}</h3>
    <p>{`On your smarthome side you now need to provide a webhook and add it to your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`HTTP Alarmserver configuration`}</a>{`. Using the test CGI command will then trigger your camera to send a HTTP GET request and you can use your smarthome to further process the event, e.g. with `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"
      }}>{`Node-RED`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "799px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/21568afd7848434c363d89526e8bc138/76cea/Alarmserver_Testing_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.086956521739133%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAl0lEQVQI103LTQuCMACAYf//oXuX/kIHj5nrAwrKBWWCgbKFqCubmk2zObcQFHx4r6/G+ZdzNvThTc1iN3f0Eu9ZSbLUrligBlLKdkTLc4yQ6fsmxiuElkFoEQdExiRcTzNvczjrczAD0DDhYmdvn/ShlBJC9LPoNKNEXdAquRex93sFUULgDZ5cC7rHq39J31RJJdp+/gPPFaP++dDYRwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/21568afd7848434c363d89526e8bc138/e4706/Alarmserver_Testing_03.avif 230w", "/en/static/21568afd7848434c363d89526e8bc138/d1af7/Alarmserver_Testing_03.avif 460w", "/en/static/21568afd7848434c363d89526e8bc138/eb911/Alarmserver_Testing_03.avif 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/21568afd7848434c363d89526e8bc138/a0b58/Alarmserver_Testing_03.webp 230w", "/en/static/21568afd7848434c363d89526e8bc138/bc10c/Alarmserver_Testing_03.webp 460w", "/en/static/21568afd7848434c363d89526e8bc138/a2266/Alarmserver_Testing_03.webp 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/21568afd7848434c363d89526e8bc138/81c8e/Alarmserver_Testing_03.png 230w", "/en/static/21568afd7848434c363d89526e8bc138/08a84/Alarmserver_Testing_03.png 460w", "/en/static/21568afd7848434c363d89526e8bc138/76cea/Alarmserver_Testing_03.png 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/21568afd7848434c363d89526e8bc138/76cea/Alarmserver_Testing_03.png",
              "alt": "Testing the INSTAR Alarmserver",
              "title": "Testing the INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Importing this flow into Node-RED will give you a HTTP webhook `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/as-webhook/`}</code>{` on the IP address of your Node-RED installation, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.112`}</code>{`:`}</p>
    <p><strong parentName="p">{`JSON Flow Export`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"38b7bc1d.686704"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http response"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ba3a4343d1d61c4f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`430`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`700`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"eb3f53ec.7ccc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"template"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ba3a4343d1d61c4f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"page"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"field"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"fieldType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"format"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"handlebars"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"syntax"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mustache"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"template"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"<html>\\n    <head></head>\\n    <body>\\n        <h2>Camera: {{req.query.camera}}</h2>\\n        <h2>Test Value 2: {{req.query.query2}}</h2>\\n        <h2>Test Value 3: {{req.query.query3}}</h2>\\n        <h2>Active: {{req.query.active}}</h2>\\n    </body>\\n</html>"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`310`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`700`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"38b7bc1d.686704"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"679c2568.c1aa9c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ba3a4343d1d61c4f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Webhook with URL Query"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"url"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/as-webhook/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"method"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"get"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"upload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"swaggerDoc"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`130`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`720`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"eb3f53ec.7ccc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"d47269ed.fe6118"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"d47269ed.fe6118"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"function"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ba3a4343d1d61c4f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"extract query"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"func"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg.payload = msg.req.query;\\nreturn msg;"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"outputs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"noerr"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"initialize"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"finalize"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"libs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`330`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`740`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7aad73d3908357b3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7aad73d3908357b3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ba3a4343d1d61c4f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"HTTP Alarmserver"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"active"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tosidebar"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"console"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tostatus"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"complete"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"targetType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusVal"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`510`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`740`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Just configure your camera to contact the webhook in case of an alert and use the test CGI:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/791dcad7de2bb52b9f35dfe52454b72e/f2763/Alarmserver_Testing_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACN0lEQVQoz1WSy07bQBiF8wKI3IgT3+3YQZ6yKzTYcRJSY7BDXNuZmyeJA2rVFhYtSGwqVWLdgtRNu6vUZ60SNwU+Hc3u0/nnnynt7e29WGNZlmkYr5d34c1v7+rXqMhlcf6Mbv/0kktJ4C0LjEYjeU2pMAEAnd3d/quD+Ycv5OaBfv6e3Tysc59d3+NP3+a3P4bjudRSVdnQZEMR27KglQoTAGB2Or7tLiCChNKMEUoJoYRmJGMQIjI/d+xhsyo2qxJXFriy1KzJT2XzdOAtswUmGCE4TdM4jt9E0SRBURRNIXTsIVcR+brSrMqNsshVpRLYYO52jg6cBWYko1O44jQIXNe1bbvbPXTdXnf/sFVThB11Xb4a4VE2THPkDpazOUQrEyEUx/FkMjk7OxuHY4iQ6wzEuiJzutoylGZb4rTHhZmm6XlenueUUsYYpRRuSNNk+e7joO8LNUlqaBpvaC1DaCj/mi3LAsAy2p0wGDPGEFq1TzfAOH17kfWO+nxF4usqty01yyJXX49dPDIAliK3T07CRb6ghE6fkiSL84vewGtVJKGqVrf4yhZ3HETPmlXVOD0JGcsKBSGEMUYIQwiXee7afX5HEhpKbbslCmqMcOn/9wIA6LoeBMFsNkMIpmmKEKIoJdM4wSjJsv5gIMuyrmmr6LqqrO9srSnkMAwZY4QQjDHBOHt/y66+XuPsDhN/OJQURdceKVlPaLfbjuP4vu9tOE4vjvFlfuRfDUfOy31ZfSb/BU9xt1GRJ5x3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/791dcad7de2bb52b9f35dfe52454b72e/e4706/Alarmserver_Testing_04.avif 230w", "/en/static/791dcad7de2bb52b9f35dfe52454b72e/d1af7/Alarmserver_Testing_04.avif 460w", "/en/static/791dcad7de2bb52b9f35dfe52454b72e/7f308/Alarmserver_Testing_04.avif 920w", "/en/static/791dcad7de2bb52b9f35dfe52454b72e/755d7/Alarmserver_Testing_04.avif 1291w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/791dcad7de2bb52b9f35dfe52454b72e/a0b58/Alarmserver_Testing_04.webp 230w", "/en/static/791dcad7de2bb52b9f35dfe52454b72e/bc10c/Alarmserver_Testing_04.webp 460w", "/en/static/791dcad7de2bb52b9f35dfe52454b72e/966d8/Alarmserver_Testing_04.webp 920w", "/en/static/791dcad7de2bb52b9f35dfe52454b72e/b21f7/Alarmserver_Testing_04.webp 1291w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/791dcad7de2bb52b9f35dfe52454b72e/81c8e/Alarmserver_Testing_04.png 230w", "/en/static/791dcad7de2bb52b9f35dfe52454b72e/08a84/Alarmserver_Testing_04.png 460w", "/en/static/791dcad7de2bb52b9f35dfe52454b72e/c0255/Alarmserver_Testing_04.png 920w", "/en/static/791dcad7de2bb52b9f35dfe52454b72e/f2763/Alarmserver_Testing_04.png 1291w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/791dcad7de2bb52b9f35dfe52454b72e/c0255/Alarmserver_Testing_04.png",
              "alt": "Testing the INSTAR Alarmserver",
              "title": "Testing the INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will now be able to extract the appended `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"
      }}>{`URL queries`}</a>{` and feed them into your Node-RED flows:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8ff109cb4e47d3b74304b428f789d249/ee9b6/Alarmserver_Testing_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.086956521739133%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAmElEQVQI10WOuw6CQBAA+f/Cxs5/sKYzCjHaaGOjRBOIykPlwh17cI+9PcVgnEw7yQTWKN23Sg0aJfrbTpcHBbkRqXfGDxARaa150wj+R0oZQH2s0uUji6psJa5bFk1fi0mThFE8izfz9S48nfdjzFjXtvYHIgYOkdyoswahtrxCYM86z8vLvUgYK4g8SADeaVCe6LvjP/EbhS6nXxvZq/gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ff109cb4e47d3b74304b428f789d249/e4706/Alarmserver_Testing_05.avif 230w", "/en/static/8ff109cb4e47d3b74304b428f789d249/d1af7/Alarmserver_Testing_05.avif 460w", "/en/static/8ff109cb4e47d3b74304b428f789d249/7f308/Alarmserver_Testing_05.avif 920w", "/en/static/8ff109cb4e47d3b74304b428f789d249/d45c9/Alarmserver_Testing_05.avif 1041w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8ff109cb4e47d3b74304b428f789d249/a0b58/Alarmserver_Testing_05.webp 230w", "/en/static/8ff109cb4e47d3b74304b428f789d249/bc10c/Alarmserver_Testing_05.webp 460w", "/en/static/8ff109cb4e47d3b74304b428f789d249/966d8/Alarmserver_Testing_05.webp 920w", "/en/static/8ff109cb4e47d3b74304b428f789d249/e0a35/Alarmserver_Testing_05.webp 1041w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ff109cb4e47d3b74304b428f789d249/81c8e/Alarmserver_Testing_05.png 230w", "/en/static/8ff109cb4e47d3b74304b428f789d249/08a84/Alarmserver_Testing_05.png 460w", "/en/static/8ff109cb4e47d3b74304b428f789d249/c0255/Alarmserver_Testing_05.png 920w", "/en/static/8ff109cb4e47d3b74304b428f789d249/ee9b6/Alarmserver_Testing_05.png 1041w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8ff109cb4e47d3b74304b428f789d249/c0255/Alarmserver_Testing_05.png",
              "alt": "Testing the INSTAR Alarmserver",
              "title": "Testing the INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "paramcgicmdtestmqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdtestmqtt",
        "aria-label": "paramcgicmdtestmqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=testmqtt`}</h2>
    <h3 {...{
      "id": "test-the-mqtt-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#test-the-mqtt-alarmserver",
        "aria-label": "test the mqtt alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test the MQTT Alarmserver`}</h3>
    <p>{`This command allows you to emit a alarmserver MQTT update to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`configured MQTT Broker`}</a>{`. The test will update two MQTT topics - one for the alarm trigger and a second for the detected object (if `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`object detection is enabled`}</a>{`).`}</p>
    <p>{`So, for example, you want to simulate that your cameras first alarm area and (`}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`coupled`}</a>{`) the internal PIR motion sensor detected motion. And the activated `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`object detection`}</a>{` identified the object as being a person. If your cameras IP address is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.120/`}</code>{` and your admin user is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin`}</code>{` with password `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar`}</code>{` you can visit the following URL with your web browser to trigger the Alarmserver:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.120/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`testmqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`active`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`object`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/5b587/Alarmserver_Testing_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.217391304347828%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAXUlEQVQI143MSQqAMBBE0VzD7mim7pjBJIp6/6sJBsSNILzFpxYllrzXcsaweS6ey+xLDI0ok0vkEt/BlF1vymriUbpOpLi2emjlEYxEi2Bg0Ajmi0T7EP3jPf13AeOQFzcKLpX0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0131a0036f4fb136bb13dfdebfdbdc75/e4706/Alarmserver_Testing_06.avif 230w", "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/d1af7/Alarmserver_Testing_06.avif 460w", "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/7f308/Alarmserver_Testing_06.avif 920w", "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/87d2a/Alarmserver_Testing_06.avif 1010w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0131a0036f4fb136bb13dfdebfdbdc75/a0b58/Alarmserver_Testing_06.webp 230w", "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/bc10c/Alarmserver_Testing_06.webp 460w", "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/966d8/Alarmserver_Testing_06.webp 920w", "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/93852/Alarmserver_Testing_06.webp 1010w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0131a0036f4fb136bb13dfdebfdbdc75/81c8e/Alarmserver_Testing_06.png 230w", "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/08a84/Alarmserver_Testing_06.png 460w", "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/c0255/Alarmserver_Testing_06.png 920w", "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/5b587/Alarmserver_Testing_06.png 1010w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0131a0036f4fb136bb13dfdebfdbdc75/c0255/Alarmserver_Testing_06.png",
              "alt": "Testing the INSTAR Alarmserver",
              "title": "Testing the INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "alarm-trigger-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#alarm-trigger-1",
        "aria-label": "alarm trigger 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Trigger`}</h4>
    <p><strong parentName="p">{`MQTT Topic`}</strong>{`: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/triggered`}</code></p>
    <ul>
      <li parentName="ul">{`Alarm Area 1 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=1`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"1"}`}</code></li>
      <li parentName="ul">{`Alarm Area 2 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=2`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"2"}`}</code></li>
      <li parentName="ul">{`Alarm Area 3 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=3`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"3"}`}</code></li>
      <li parentName="ul">{`Alarm Area 4 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=4`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"4"}`}</code></li>
      <li parentName="ul">{`Alarm-In / PIR triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=5`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"5"}`}</code></li>
      <li parentName="ul">{`Audio Alarm triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=6`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"6"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 1 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=7`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"7"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 2 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=8`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"8"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 3 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=9`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"9"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 4 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=10`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"10"}`}</code></li>
      <li parentName="ul">{`Manual trigger (`}<em parentName="li">{`cmd=pushhostalarm`}</em>{`): `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=99`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"99"}`}</code></li>
    </ul>
    <h4 {...{
      "id": "detected-object-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#detected-object-1",
        "aria-label": "detected object 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Detected Object`}</h4>
    <p><strong parentName="p">{`MQTT Topic`}</strong>{`: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/triggered/object`}</code></p>
    <ul>
      <li parentName="ul">{`Person: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=1`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"1"}`}</code></li>
      <li parentName="ul">{`Vehicle: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=2`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"2"}`}</code></li>
      <li parentName="ul">{`Person + Vehicle: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=3`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"3"}`}</code></li>
      <li parentName="ul">{`Animal: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=4`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"4"}`}</code></li>
      <li parentName="ul">{`Person + Animal: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=5`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"5"}`}</code></li>
      <li parentName="ul">{`Animal + Vehicle: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=6`}</code>{` -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"6"}`}</code></li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Both topic will reset to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`{"val":"0"}`}</code>{` 5 seconds after they were triggered.`}</p>
    </blockquote>
    <h3 {...{
      "id": "smarthome-integration-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#smarthome-integration-1",
        "aria-label": "smarthome integration 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Integration`}</h3>
    <p>{`Now add to `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
      }}>{`MQTT-In nodes to Node-RED`}</a>{` and subscribe them to the topics `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/triggered`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/triggered/object`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/e3189/Alarmserver_Testing_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABSElEQVQoz4XRW2+CMBgGYH75LvdPtmxZlk2jmfOEyMADlJMR47VXLDFQ7AloYaGNLjExPhdfevO275dq9mxmfI/M4dhZLBzX6Q973UGn8/VprX5gBo/HI5LwBUKU84O1dB8etdl4Ou4Npv2hOTGWq+V77+21+/L88WRYOiX0dDoJIbgkzjjn7WWUapa9mBumZdq2aa/Xa32uT43JSB8BH1BKOedFUUAI8zynlNZ1LYSopKZptEAKozAI20MURptoEwThfr/HGHHOKaWplOc5Y+zyeBv2PA8AcDUdx4njGCGkHqESY6woiqZpaqkN+77ved7VdF13t9thjKuKE8bKslRJJqnO98OYkMNvkiSJag4hLMtSdb4ZBgBst9tCwhinaYoQYoypwv873wrHcUwIUYWzLINSLhFChBB3ahNCqqpSX6X2rM9U+A8ohBPl3dvv1gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/e4706/Alarmserver_Testing_07.avif 230w", "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/d1af7/Alarmserver_Testing_07.avif 460w", "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/7f308/Alarmserver_Testing_07.avif 920w", "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/eeaf8/Alarmserver_Testing_07.avif 1035w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/a0b58/Alarmserver_Testing_07.webp 230w", "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/bc10c/Alarmserver_Testing_07.webp 460w", "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/966d8/Alarmserver_Testing_07.webp 920w", "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/77d2c/Alarmserver_Testing_07.webp 1035w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/81c8e/Alarmserver_Testing_07.png 230w", "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/08a84/Alarmserver_Testing_07.png 460w", "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/c0255/Alarmserver_Testing_07.png 920w", "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/e3189/Alarmserver_Testing_07.png 1035w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c117d2dbc1e5706a5fdad9ebe560ee48/c0255/Alarmserver_Testing_07.png",
              "alt": "Testing the INSTAR Alarmserver",
              "title": "Testing the INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`JSON Flow Export`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"54afb9abe15aff3e"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ba3a4343d1d61c4f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/triggered"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/120/status/alarm/triggered"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"datatype"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto-detect"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"46447b87141376a9"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"nl"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rap"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rh"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"inputs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`880`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ffef0f0ce85fc1d3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b8f8da9b44a42bef"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ba3a4343d1d61c4f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/triggered/object"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/120/status/alarm/triggered/object"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"datatype"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto-detect"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"46447b87141376a9"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"nl"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rap"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rh"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"inputs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`120`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`940`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"016b47c77d7214df"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ffef0f0ce85fc1d3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ba3a4343d1d61c4f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MQTT Alarmserver"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"active"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tosidebar"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"console"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tostatus"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"complete"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"targetType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusVal"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`350`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`880`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"016b47c77d7214df"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ba3a4343d1d61c4f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MQTT Alarmserver"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"active"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tosidebar"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"console"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tostatus"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"complete"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"targetType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusVal"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`350`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`940`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"46447b87141376a9"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt-broker"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"INSTAR MQTTv5 Broker"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.120"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"port"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1883"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"769e462e1dcaf995"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"clientid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Node-RED"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"autoConnect"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"usetls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"protocolVersion"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"keepalive"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"cleansession"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"nodered/lwt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthRetain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"alive\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthMsg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"contentType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"application/json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"nodered/lwt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeRetain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closePayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"offline\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeMsg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"contentType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"application/json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"nodered/lwt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willRetain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"dead\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willMsg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"contentType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"application/json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"userProps"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"sessionExpiry"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"769e462e1dcaf995"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"tls-config"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.120"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"cert"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"ca"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"certname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"client.crt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"keyname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"client.key"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"caname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ca.crt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"servername"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"verifyservercert"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"alpnprotocol"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`When you use the test CGI command you will first see the trigger and object variable you appended to the command. And after `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`5s`}</code>{` both values will reset to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "837px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/affdeb5f09cd80d9a5b41c89c806b252/ddc81/Alarmserver_Testing_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABOUlEQVQoz42QXUsCURCG9///m0IKQtOiUopUxK/WcjXJIM/mzpkz52vPHGOVgm6yh7mYm3ce3kk2b5vleLkYL9aztTXWOtsZ3TUf61e9y+t+c7qYhBCUUgWAlBIAthXF+4cAqZJsvpp2p/Phy+ppBTkYY9rD28bDRbNbb/Uak2wUAitEyHO13VIBJEEBGERnTJI9v47ux2k3TbtpNshQYqvXqN2cnLVPzzu1waxfmUkRklXaWeuds9aWZRnKkJCzzjuOHHcx7iJH3v2mLMtPkWsAS2QQtQSNqA/myBXhm8Nend0PM3vvhRBYSEvaGeO9d85V5hCSwH8RY/Te50IQSCoK2rfVUhpUTpuEjxFCQJSkdGW2xu07e+8r89Ewx4gSpBAG0aAkAKrM+878DzSR0459ySH8PJKZvwBAXv/t2HgTagAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/affdeb5f09cd80d9a5b41c89c806b252/e4706/Alarmserver_Testing_08.avif 230w", "/en/static/affdeb5f09cd80d9a5b41c89c806b252/d1af7/Alarmserver_Testing_08.avif 460w", "/en/static/affdeb5f09cd80d9a5b41c89c806b252/60ad0/Alarmserver_Testing_08.avif 837w"],
              "sizes": "(max-width: 837px) 100vw, 837px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/affdeb5f09cd80d9a5b41c89c806b252/a0b58/Alarmserver_Testing_08.webp 230w", "/en/static/affdeb5f09cd80d9a5b41c89c806b252/bc10c/Alarmserver_Testing_08.webp 460w", "/en/static/affdeb5f09cd80d9a5b41c89c806b252/a6125/Alarmserver_Testing_08.webp 837w"],
              "sizes": "(max-width: 837px) 100vw, 837px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/affdeb5f09cd80d9a5b41c89c806b252/81c8e/Alarmserver_Testing_08.png 230w", "/en/static/affdeb5f09cd80d9a5b41c89c806b252/08a84/Alarmserver_Testing_08.png 460w", "/en/static/affdeb5f09cd80d9a5b41c89c806b252/ddc81/Alarmserver_Testing_08.png 837w"],
              "sizes": "(max-width: 837px) 100vw, 837px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/affdeb5f09cd80d9a5b41c89c806b252/ddc81/Alarmserver_Testing_08.png",
              "alt": "Testing the INSTAR Alarmserver",
              "title": "Testing the INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      